import React from "react";
import styled from "styled-components";
import { Button } from "@mui/material";
import theme from "../../theme/theme";
import { FONT_ROBOTO_CONDENSED } from "../../styles";

const ButtonLightStyled = styled(Button)({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.pale.main,
  fontSize: 16,
  ...FONT_ROBOTO_CONDENSED.BOLD,
});

export const ButtonLight = ({ children, ...props }) => {
  return (
    <ButtonLightStyled variant="contained" size="medium" {...props}>
      {children}
    </ButtonLightStyled>
  );
};
