import React from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import styled from "styled-components";

const PaperWrapper = styled(Paper)({
  padding: 20,
  marginTop: -10,
});

const ImageWrapper = styled("img")({
  maxWidth: "100%",
});

export const SectionPaper = ({ img, children, altImg }) => {
  return (
    <PaperWrapper elevation={1}>
      <Grid>
        {img && (
          <Grid item xs={12} md={6}>
            <ImageWrapper src={img} alt={altImg} />
          </Grid>
        )}
        <Grid item xs={12} md={6} sx={{ pt: 2 }}>
          {children}
        </Grid>
      </Grid>
    </PaperWrapper>
  );
};
