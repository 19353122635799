import React, { useState } from "react";
import Alert from "@mui/material/Alert";

export const Notification = ({ children, type, ...props }) => {
  const [showNotification, setShowNotification] = useState(true);
  return showNotification ? (
    <Alert
      onClose={() => {
        setShowNotification(false);
      }}
      style={{
        width: "100%",
        margin: "30px 0",
        boxShadow: "0px 0px 20px rgb(0 0 0 / 10%)",
      }}
      severity={type}
      {...props}
    >
      {children}
    </Alert>
  ) : null;
};
