import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Icon from "@mui/material/Icon";
import styled from "styled-components";
import theme from "../../theme/theme";

const BoxStyled = styled(Box)({
  background: theme.palette.primary.main,
  borderRadius: "100px",
  width: 42,
  height: 42,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px 15px 10px",
  '& svg': {
    color: '#fff'
  }
});

const LinkItemStyled = styled("a")({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textDecoration: "none",
  color: "black",
});

const TitleItemStyled = styled(Typography)({
  fontSize: '16px !important',
  fontWeight: "bold",
});

export const IconWrapper = ({
  smallText,
  bigText,
  icon,
  linkType,
  linkTarget,
  ...props
}) => {
  const HAS_LINK = linkType !== undefined;
  const IS_PHONE = linkType === "phone";
  let hrefData = HAS_LINK
    ? {
        href: IS_PHONE
          ? `tel:${linkTarget}`
          : linkTarget,
        title: IS_PHONE
          ? `Presiona para comunicarte al ${linkTarget}`
          : linkTarget,
        target: IS_PHONE ? "_self" : "_blank",
      }
    : {};

  const MenuItem = () => (
    <>
      <Box>
        <Typography style={{ fontSize: 14, fontWeight: 'bold'  }}>{smallText}</Typography>
        <TitleItemStyled>{bigText}</TitleItemStyled>
      </Box>
      <BoxStyled>
        <Icon component={icon} />
      </BoxStyled>
    </>
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{ pl: 2, pb: 2, pt: 3, borderBottom: '1px solid #ddd' }}
      justifyContent="space-between"
    >
      {HAS_LINK ? (
        <LinkItemStyled {...hrefData}>
          {" "}
          <MenuItem />
        </LinkItemStyled>
      ) : (
        <MenuItem />
      )}
    </Box>
  );
};
