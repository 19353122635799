import React from "react";
import { Box, Typography, Modal, Button } from "@mui/material";

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import theme from "../../theme/theme";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '80vh',
  width: '80vw',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding: '30px',
  overflowY: 'auto',
  paddingTop: '20px',
  '& p': {
    marginBottom: '30px',
  },
  '& h2': {
    fontSize: '36px',
    marginBottom: '20px',
    [theme.breakpoints.down("md")]: {
      fontSize: '28px',
    }
  }
};

export const InfraredModal = ({ 
  handleClose,
  open,
}) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box justifyContent={'space-between'} display='flex' alignItems={'flex-start'}>
          <Typography id="modal-modal-title" variant="h4" component="h2" sx={{ color: '#056734'}}>Diagnóstico Avanzado del Dolor Neuromioarticular</Typography>
          <IconButton aria-label="delete" size="medium" onClick={handleClose} sx={{ marginLeft: '-10px'}}>
            <CloseIcon fontSize="large" color="primary" sx={{ '& svg': { color: '#00BA9E' } }} />
          </IconButton>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Con este nuevo método de diagnóstico por imagen, es posible evaluar si existe actividad inflamatoria nociva en estructuras corporales como tendones, músculos, nervios y vasos sanguíneos mucho antes que el dolor aparezca.
        </Typography>
        <Box sx={{
          background: '#f3f3f3', padding: '20px', lineHeight: '1.5em', marginBottom: '30px !important', borderLeft: '2px solid #00BA9E', '& p': {
            lineHeight: '1.7em',
            marginBottom: 0
          }
        }}>
          <Typography>
            <em>"Realizamos un estudio de todo el cuerpo con equipos de alta sensibilidad y resolución que nos permiten comprobar con precisión cuáles son las estructuras corporales afectadas. Estas suelen ser la causa del dolor, como en casos de hernias discales, lesiones miofasciales, deportivas y degenerativas"</em>, según explica el <strong>Dr. Federico Brandl</strong>, Jefe del Servicio de Termografía Multiespectral de Fisioespalda.
          </Typography>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          Las indicaciones del examen son amplias y benefician desde personas con dolor crónico causado por enfermedades como <strong>Fibromialgia, Cefalea, Lumbalgia</strong>, afecciones articulares de los 4 miembros hasta aquellas con lesiones por esfuerzos repetitivos del trabajo o deportes.
          El <Typography sx={{ color: '#00BA9E' }} component={'span'}>#INFRARED</Typography> es un estudio no invasivo, que no requiere medios de contraste ni radiaciones, se realiza en 30 minutos, sin necesidad de equipos cerrados como el tunel de un resonador.
        </Typography>
        <Box display={'flex'} justifyContent={'center'}>
          <Button variant="text" onClick={handleClose} sx={{ color: '#00BA9E', backgroundColor: 'rgba(107, 180, 46, 0.1)' }}>Cerrar Ventana</Button>
        </Box>
      </Box>
    </Modal>
  )
}