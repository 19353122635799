import React from 'react'
import styled from 'styled-components'
import { Typography, Link, Container, Grid } from '@mui/material'
import { Box } from '@mui/system'
import LogoFooterImg from '../../assets/logo-blanco.png'
import { mapSiteLinks } from './../../data'
import { FONT_ROBOTO_CONDENSED } from '../../styles'
import theme from '../../theme/theme'

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined'

const FooterContainer = styled(Box)({
  backgroundColor: '#358A33',
  marginTop: 100
})

const FooterTopInnerContainer = styled(Box)({
  minHeight: '100px',
  padding: 40
})

const FooterInnerContainer = styled(Box)({
  backgroundColor: '#056734',
  minHeight: '60px',
  padding: '10px 40px 10px'
})

const FooterMapTitle = styled(Typography)({
  color: '#6BB42E',
  fontSize: '20px !important',
  textTransform: 'uppercase'
})

const FooterMapLink = styled(Link)({
  color: 'white !important',
  fontSize: 16,
  display: 'flex',
  alignItems: 'center',
  ...FONT_ROBOTO_CONDENSED.REGULAR
})

const TrademarkFooterText = styled(Typography)({
  ...FONT_ROBOTO_CONDENSED.REGULAR,
  width: '100%',
  textAlign: 'center'
})

const FooterContactBox = styled(Container)({
  display: 'flex !important',
  flexDirection: 'column',
  justifyContent: 'space-between'
  // minHeight: 250,
})

const FooterMapContainer = styled(Box)(
  {
    /* maxWidth: "66%", */
  }
)

const FooterLinkList = styled('ul')({
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    // columns: 2,
    maxWidth: '100%'
  },
  // columns: 3,
  listStyle: 'none',
  paddingLeft: 0
  // maxWidth: '66%'
})

const FooterLogo = styled('img')({
  width: 'auto',
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: 30
  }
})

const FooterTopInnerContent = styled(Container)({
  display: 'flex !important',
  width: '100%',
  justifyContent: 'space-between',
  [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
    flexDirection: 'column',
    alignItems: 'center'
  }
})

export const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <FooterContainer>
      <FooterTopInnerContainer>
        <FooterTopInnerContent>
          <Box>
            <a href='./' title='Logo Fisioespalda'>
              <FooterLogo src={LogoFooterImg} alt='Logo de Fisioespalda' />
            </a>
          </Box>
          {mapSiteLinks &&
            mapSiteLinks.length &&
            <Grid container width={'auto'}>
              {[
                {
                  icon: <WhatsAppIcon />,
                  text: '098.03.15.15',
                  link: 'https://api.whatsapp.com/send?phone=59898031515',
                  otherStyles: {}
                },
                {
                  icon: <LocationOnOutlinedIcon />,
                  text: 'Av. 18 de Julio 2066 bis Oficina 203, Montevideo',
                  link:
                    'https://www.google.com/maps/place/FISIOESPALDA/@-34.899993,-56.1731169,17z/data=!3m1!4b1!4m6!3m5!1s0x959f805370a07601:0xd60c55dd509df7fe!8m2!3d-34.8999974!4d-56.170542!16s%2Fg%2F11cn3k_h2c?entry=ttu',
                  otherStyles: {
                    position: 'relative',
                    left: '-3px',
                    transform: 'scale(1.1)'
                  }
                }
                // {
                //   icon: <QueryBuilderOutlinedIcon />,
                //   text: 'Horario',
                //   link: 'https://api.whatsapp.com/send?phone=098031515'
                // }
              ].map((siteLink, index) => {
                return (
                  <Grid
                    item
                    display={'flex'}
                    sx={{ color: 'white', marginRight: '30px' }}
                    alignItems={'center'}
                    justifyContent={'center'}
                  >
                    <Link
                      href={siteLink.link}
                      target='_blank'
                      sx={{
                        color: 'white',
                        '& svg': { opacity: 0.5 },
                        display: 'flex',
                        [`@media (max-width: ${theme.breakpoints.values
                          .lg}px)`]: {
                            marginBottom: '15px'
                          }
                      }}
                    >
                      <Box
                        display={'flex'}
                        alignContent={'center'}
                        alignItems={'center'}
                        sx={{ ...siteLink.otherStyles}}
                      >
                        {siteLink.icon}
                      </Box>
                      <Box sx={{ paddingLeft: '10px' }}>
                        {siteLink.text}
                      </Box>
                    </Link>
                  </Grid>
                )
              })}
            </Grid>}
        </FooterTopInnerContent>
      </FooterTopInnerContainer>
      <FooterInnerContainer>
        <FooterContactBox>
          <TrademarkFooterText color='primary'>
            Todos los derechos reservados {currentYear} - Fisioespalda es una
            marca registrada
          </TrademarkFooterText>
        </FooterContactBox>
      </FooterInnerContainer>
    </FooterContainer>
  )
}
