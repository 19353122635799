import { createTheme } from '@mui/material/styles';
import { GREEN_LIGHT, GREEN_PRIMARY, GREEN_SECONDARY, GREEN_PALE } from '../styles';

const colors = {
  main: '#6BB42E',
  secondary: '#056734',
  tertiary: '#00BA9E',
  pale: '#F7F8E4'
}

const theme = createTheme({
  palette: {
    primary: {
      main: GREEN_PRIMARY,
    },
    secondary: {
      main: GREEN_SECONDARY,
    },
    tertiary: {
      main: GREEN_LIGHT,
    },
    pale: {
      main: GREEN_PALE
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: 'white',
          border: 0,
          padding: '13px 29px 12px',
          fontSize: 18,
          textAlign: 'center',
          fontFamily: "Roboto Condensed, sans-serif",
          fontWeight: 400,
          lineHeight: '1em',
          borderRadius: 10,
          textTransform: 'uppercase',
          backgroundColor: GREEN_LIGHT,
          boxShadow: 'none'
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          minHeight: 50,
        },
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    }
  }
});

export default theme;