import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/material";
import styled from "styled-components";

const LoadingContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
});

export const Loading = () => {
  return (
    <LoadingContainer>
      <CircularProgress />
    </LoadingContainer>
  );
};
