export const BLACK = '#000';
export const WHITE = '#fff';
export const GREEN_PRIMARY = '#6BB42E';
export const GREEN_SECONDARY = '#056734';
export const GREEN_PALE = '#F7F8E4';
export const GREEN_LIGHT = '#00BA9E';
export const GREEN_THIRD = '#358A33';
export const YELLOW_PALE = '#E1E289'

export const MAIN_PADDING = '40px';

const ROBOTO_FAMILY = '"Roboto", sans-serif !important';
const ROBOTO_CONDENSED = '"Roboto Condensed", sans-serif !important';

export const FONT_ROBOTO = {
  LIGHT: {
    fontFamily: ROBOTO_FAMILY,
    fontWeight: '300 !important'
  },
  REGULAR: {
    fontFamily: ROBOTO_FAMILY,
    fontWeight: '400 !important'
  },
  MEDIUM: {
    fontFamily: ROBOTO_FAMILY,
    fontWeight: '500 !important'
  },
  BOLD: {
    fontFamily: ROBOTO_FAMILY,
    fontWeight: '700 !important'
  }
}

export const FONT_ROBOTO_CONDENSED = {
  LIGHT: {
    fontFamily: ROBOTO_CONDENSED,
    fontWeight: '300 !important'
  },
  REGULAR: {
    fontFamily: ROBOTO_CONDENSED,
    fontWeight: '500 !important'
  },
  BOLD: {
    fontFamily: ROBOTO_CONDENSED,
    fontWeight: '700 !important'
  }
}