export const infraredContent = {
  subtitle: 'Diagnóstico Avanzado <br />del Dolor Neuromioarticular',
  title: 'Infrared',
  text: `Con este nuevo método de diagnóstico por imagen, es posible evaluar si existe actividad inflamatoria nociva en estructuras corporales como tendones, músculos, nervios y vasos sanguíneos mucho antes que el dolor aparezca.`,
  buttonLink: '#contact-form-section',
}

export const items = [
  {
    name: 'Nos encanta cuidarte',
    description: 'Porque tu dolor no puede esperar, te esperamos en la clínica con todas las medidas de seguridad sanitaria para cuidarte y cuidarnos.',
    buttonLink: '#contact-form-section',
    buttonText: 'Agendar Consulta',
    image: 'fisioespalda-slide-home-1.jpg'
  },
  {
    name: 'Pagá online',
    description: 'Ahora podés abonar tu cuota mensual con tu medio de pago preferido, de manera rápida, ágil y segura.',
    buttonLink: 'https://mpago.la/1Fkc7dB',
    buttonText: 'Pagar cuota',
    image: 'fisioespalda-slide-home-2.jpg'
  }
]

export const featuredList = [
  {
    title: 'Especialistas',
    description: 'Una larga trayectoria nos avala en el tratamiento de afecciones de columna.',
    icon: 'medical'
  },
  {
    title: 'Infrared',
    description: 'Somos pioneros en Uruguay en su uso para diagnóstico.',
    icon: 'infrared'
  },
  {
    title: 'Consulta Integral',
    description: 'Nuestro enfoque comprende la totalidad del paciente.',
    icon: 'approach'
  },

  {
    title: 'Puntualidad',
    description: 'Tu tiempo importa, agendás, llegás y te atendés!',
    icon: 'time'
  },
]

export const mapSiteLinks = [
  {
    title: 'Inicio',
    link: '/'
  },
  {
    title: 'Metodología',
    link: '/metodologia'
  },
  {
    title: 'Patologías',
    link: '/patologias'
  },
  {
    title: 'Clínica',
    link: '/clinica'
  },
  {
    title: 'Servicios',
    link: '/servicios'
  },
  {
    title: 'Tratamientos',
    link: '/tratamientos'
  },
  {
    title: 'Infrared',
    link: '/infrared'
  },
  {
    title: 'Ubicación',
    link: '/ubicacion'
  }
]