import React, { useState } from 'react'
import { Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import Logo from './../../assets/logo.png'
import { Link } from "react-router-dom";
import styled from 'styled-components';

import MenuIcon from '@mui/icons-material/Menu';

const ImgLogo = styled('img')({
  maxWidth: '100%'
})

export const Header = ({ onClickMenu }) => {

  return (
    <Box>
      <Grid container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Grid item xs={8}>
          <Link to="/">
            <ImgLogo src={Logo} />
          </Link>
        </Grid>
        <Grid>
          <IconButton onClick={onClickMenu} style={{
            color: '#6BB42E',
            background: '#F7F8E4'
          }}>
            <MenuIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  )
}
