import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { IconWrapper } from "../IconWrapper/IconWrapper";

import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined";
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import styled from "styled-components";

const DrawerContent = styled(Box)((props) => ({
  width: "30vw",
  backgroundColor: "white",
  "@media(max-width: 1024px)": {
    width: "85vw",
  },
}));

const DrawerMenu = ({
  drawerState,
  handleDrawerState,
  state,
  handleNavigation,
  children,
  ...props
}) => {
  return (
    <Drawer
      state={drawerState}
      onClose={handleDrawerState}
      open={state}
      {...props}
    >
      <DrawerContent>
        <List style={{ paddingTop: 0 }}>
          {[
            {
              section: "Inicio",
              link: "/",
            },
            /*             {
              section: "Infrared",
              link: "/infrared",
            }, */
          ].map((menuItem, index) => (
            <Box key={index}>
              <ListItem
                style={{ minHeight: 50 }}
                disablePadding
                onClick={() => handleNavigation(menuItem.link)}
              >
                <ListItemButton>
                  <ListItemText primary={menuItem.section} />
                </ListItemButton>
              </ListItem>
              <Divider />
            </Box>
          ))}
        </List>
        <Box>
          <IconWrapper
            icon={WhatsAppIcon}
            smallText="Agendate a nuestro whatsapp"
            bigText="098.03.15.15"
            linkType="whatsapp"
            linkTarget="https://api.whatsapp.com/send?phone=59898031515"
          />
          <IconWrapper
            icon={LocationOnOutlinedIcon}
            smallText="Encontranos en"
            bigText="Av. 18 de Julio 2066 bis of/203"
            linkType="location"
            linkTarget="https://www.google.com/maps/place/FISIOESPALDA/@-34.899993,-56.1731169,17z/data=!3m1!4b1!4m6!3m5!1s0x959f805370a07601:0xd60c55dd509df7fe!8m2!3d-34.8999974!4d-56.170542!16s%2Fg%2F11cn3k_h2c?entry=ttu3"
          />
          <IconWrapper
            icon={QueryBuilderOutlinedIcon}
            smallText="Horario"
            bigText="Lun a Vie 13:30 - 19:30"
          />
        </Box>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerMenu;
