import React from "react";
import { Layout } from "../Layout/Layout";

import { Typography } from "@mui/material";
import { BoxTitle } from "../BoxTitle/BoxTitle";
import { ButtonWrapper } from "../Button/Button";
import ImgInfrared from "../../assets/infrared-home.jpg";
import { SectionPaper } from "../SectionPaper/SectionPaper";

export const Infrared = () => {
  return (
    <Layout>
      <BoxTitle sx={{ mt: 2 }} subtitle="Conocé más sobre" title="Infrared" />
      <SectionPaper img={ImgInfrared} altImg="Diagnóstico de dolores Infrared">
        <Typography>
          <strong>INFRARED</strong> es la última tecnología en sensores
          infrarojos y nos permite generar imágenes térmicas 3D de alta
          resolución en tiempo real.
        </Typography>
        <ButtonWrapper variant="contained">Agendar Consulta</ButtonWrapper>
      </SectionPaper>
    </Layout>
  );
};
