import React from "react";
import { Typography, Box } from "@mui/material";
import styled from "styled-components";
import theme from "../../theme/theme";
import { FONT_ROBOTO, FONT_ROBOTO_CONDENSED } from "../../styles";

const BoxStyled = styled(Box)({
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  paddingTop: 40,
  [`@media (min-width: ${theme.breakpoints.values.md}px)`]: {
    maxWidth: "calc(60% - 40px)",
    padding: "40px 0",
    margin: "0 40px 0 0",
  },
});

const FeaturedTitleStyled = styled(Typography)({
  color: theme.palette.secondary.main,
  fontSize: "36px !important",
  textAlign: "center",
  marginBottom: "45px !important",
  fontFamily: '"Playfair Display", serif',
});

const FeaturedListStyled = styled(Box)({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-around",
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    flexWrap: "nowrap",
    flexDirection: "column",
  },
});

const FeaturedItemStyled = styled(Box)({
  display: "flex",
  maxWidth: "90%",
  flexBasis: "100%",
  flexGrow: 1,
  marginBottom: 50,
  marginRight: 0,
  alignItems: "center",
  [`@media (min-width: ${theme.breakpoints.values.lg}px)`]: {
    flexGrow: 0,
    flexBasis: "calc(50% - 45px)",
    alignItems: "initial",
    "&:nth-child(odd)": {
      marginRight: 5,
    },
  },
});

const FeaturedItemContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  minWidth: 93,
  height: 93,
  borderWidth: 2,
  borderStyle: "solid",
  borderColor: theme.palette.primary.main,
  borderRadius: "50%",
});

const FeaturedImageStyled = styled("img")({
  maxHeight: "50%",
});

const FeaturedItemTextBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  marginLeft: 25,
});

const FeaturedItemTitle = styled(Typography)({
  color: theme.palette.secondary.main,
  fontSize: "20px !important",
  textTransform: "uppercase",
  ...FONT_ROBOTO.REGULAR,
  /* fontFamily: '"Roboto Condensed", sans-serif !important', */
});

const FeaturedItemText = styled(Typography)({
  fontSize: "14px !important",
  ...FONT_ROBOTO.REGULAR,
});

const Featured = ({ items }) => {
  return !items ? (
    <div>No hay items</div>
  ) : (
    <BoxStyled id="featured-items-section">
      <FeaturedTitleStyled component="h4">
        Mirá lo que ofrecemos:
      </FeaturedTitleStyled>
      <FeaturedListStyled>
        {items.map((item, index) => (
          <FeaturedItemStyled key={index}>
            <FeaturedItemContainer>
              <FeaturedImageStyled
                alt={item.description}
                src={require(`./../../assets/${item.icon}.svg`)}
              />
            </FeaturedItemContainer>
            <FeaturedItemTextBox>
              <FeaturedItemTitle component="h6">{item.title}</FeaturedItemTitle>
              <FeaturedItemText component="p">
                {item.description}
              </FeaturedItemText>
            </FeaturedItemTextBox>
          </FeaturedItemStyled>
        ))}
      </FeaturedListStyled>
    </BoxStyled>
  );
};

export default Featured;
