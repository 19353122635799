import React from "react";
import { Layout } from "../Layout/Layout";
import { Container, Box, Typography, Modal, Button } from "@mui/material";
import Featured from "../Featured/Featured";
import InfraredHome from "../InfraredHome/InfraredHome";
import { ContactForm } from "../ContactForm/ContactForm";
import { featuredList, infraredContent } from "../../data";
import theme from "../../theme/theme";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { InfraredModal } from "../InfraredModal/InfraredModal";

import styled from "styled-components";

const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-self: center;
  flex-wrap: wrap;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

const HomeContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SecondaryBox = styled(Box)({
  display: "flex",
  width: "100%",
  [`@media (max-width: ${theme.breakpoints.values.md}px)`]: {
    flexDirection: "column",
  },
});

export const Home = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Layout>
      <HomeContent>
        <Container maxWidth="lg" disableGutters>
          <HomeContainer>
            <InfraredModal
              handleClose={handleClose}
              open={open}
            />
            <InfraredHome
              title={infraredContent.title}
              subtitle={infraredContent.subtitle}
              text={infraredContent.text}
              onClick={handleOpen}
            // link={infraredContent.buttonLink}
            />
            <SecondaryBox>
              <Featured items={featuredList} />
              <ContactForm />
            </SecondaryBox>
            {/* <ContactForm libraries={libraries} /> */}
          </HomeContainer>
        </Container>
      </HomeContent>
    </Layout>
  );
};
