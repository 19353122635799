import React from "react";
import Button from "@mui/material/Button";

export const ButtonWrapper = ({ children, ...props }) => {
  return (
    <Button sx={{ mt: 4 }} variant="outlined" {...props}>
      {children}
    </Button>
  );
};
