import React from "react";
import { Container, Typography, Button, Box } from "@mui/material";
import AnchorLink from "react-anchor-link-smooth-scroll";
import styled from "styled-components";
import theme from "../../theme/theme";
import { ButtonLight } from "../ButtonLight/ButtonLight";
import { FONT_ROBOTO } from "../../styles";

const MAIN_PADDING = 40;

const LinkInfrared = styled(AnchorLink)({
  textDecoration: "none",
});

const InfraredContainer = styled(Container)({
  position: "relative",
  padding: `${MAIN_PADDING}px 0 0 !important`,
  alignItems: "flex-start",
  justifyContent: "space-between",
  marginBottom: MAIN_PADDING,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    // order: 1,
  },
  [theme.breakpoints.down("sm")]: {
    padding: `calc(${MAIN_PADDING} / 2)`,
    paddingBottom: 0,
    // order: 1,
  },
});

const InfraredTextContainer = styled("div")({
  maxWidth: "60%",
  paddingRight: 50,
  [theme.breakpoints.down("md")]: {
    maxWidth: "100%",
    marginBottom: 40,
    paddingRight: 0,
    // order: 1,
  },
  [theme.breakpoints.down("sm")]: {
    maxWidth: "none",
    textAlign: "center",
  },
});

const InfraredTitleContainer = styled(Typography)({
  color: theme.palette.secondary.main,
  fontSize: "66px !important",
  marginBottom: "35px !important",
  display: "flex",
  flexDirection: "column",
  lineHeight: "1em",
  "&, & span": {
    fontFamily: '"Playfair Display", serif !important',
  },
  "& span": {
    fontSize: "30px !important",
    marginBottom: '-10px'
  },
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "46px !important",
    "& span": {
      fontSize: "22px !important",
    },
  },
});

const InfraredTextContent = styled(Typography)({
  fontSize: "20px !important",
  marginBottom: "45px !important",
  ...FONT_ROBOTO.LIGHT,
  [`@media (max-width: ${theme.breakpoints.values.sm}px)`]: {
    fontSize: "16px !important",
  },
});

const InfraredImage = styled("div")({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    minWidth: 635,
    minHeight: 398,
  },
  "& img": {
    width: "100%",
  },
});

const stylesButton = {
  marginRight: '15px',
  background: 'white',
  color: '#00BA9E',
  border: '1px solid #00BA9E',
  '&:hover': {
    background: '#e5e5e5'
  },
  [theme.breakpoints.down("md")]: {
    width: '100%',
  }
}

const InfraredHome = ({ subtitle, title, text, link, onClick }) => {
  /* const Html2React = libraries.html2react.Component; */

  return (
    <InfraredContainer sx={{ display: "flex" }}>
      <InfraredTextContainer>
        <InfraredTitleContainer component="h4">
          <Typography
            component="span"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          ></Typography>
          {title}
        </InfraredTitleContainer>
        <InfraredTextContent>{text}</InfraredTextContent>
        <Box display={'flex'} sx={{
          [theme.breakpoints.down("md")]: {
            flexDirection: 'column-reverse'
          }
        }}>
          <ButtonLight variant="contained" size="medium" onClick={() => onClick && onClick()} sx={{ ...stylesButton }}>
            Conocer más
          </ButtonLight>
          <a href='https://api.whatsapp.com/send?phone=59898031515'>
            <ButtonLight variant="contained" size="medium" sx={{ [theme.breakpoints.down("md")]: { marginBottom: '20px', width: '100%' } }}>
              Agendar Consulta
            </ButtonLight>
          </a>
        </Box>
      </InfraredTextContainer>
      <InfraredImage>
        <img src={require("../../assets/infrared-home.jpg")} alt="Infrared" />
      </InfraredImage>
    </InfraredContainer>
  );
};

export default InfraredHome;
