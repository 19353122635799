import React, { useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { Header } from "../Header/Header";
import Drawer from "../Drawer/Drawer";
import theme from "../../theme/theme";
import { Footer } from "../Footer/Footer";

export const Layout = ({ children }) => {
  const [drawerState, setDrawerState] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleDrawerState = () => setDrawerState(!drawerState);

  const handleNavigation = (path) => {
    navigate(path);
    if (location.pathname === path) {
      setDrawerState(false);
    }
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container>
          <Drawer
            state={drawerState}
            anchor="right"
            variant="temporary"
            onClose={handleDrawerState}
            handleNavigation={handleNavigation}
          />
          <Header onClickMenu={handleDrawerState} />
          {children}
        </Container>
        <Footer />
      </ThemeProvider>
    </>
  );
};
