import { Container } from "@mui/system";
import { Typography, TextField } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { ButtonLight } from "../ButtonLight/ButtonLight";

import {
  GREEN_PALE,
  GREEN_PRIMARY,
  GREEN_SECONDARY,
  WHITE,
} from "../../styles";
import emailjs from "emailjs-com";
import { useState } from "react";
import { Loading } from "../Loading/Loading";
import { Notification } from "../Notification/Notification";

const ContactFormContainer = styled(Container)({
  background: GREEN_PALE,
  width: "100%",
  padding: "50px !important",
  "& form": {
    display: "flex !important",
    flexDirection: "column",
    alignItems: "end",
    width: "100%",
  },
});

const ContactFormTitle = styled(Typography)({
  display: "block",
  color: GREEN_SECONDARY,
  fontSize: 36,
  textAlign: "center",
  marginBottom: "45px !important",
});

const InputField = styled(TextField)({
  color: GREEN_PRIMARY,
  width: "100%",
  fontSize: 16,
  /* fontFamily: '"Roboto", sans-serif', */
  fontWeight: 300,
  background: WHITE,
  marginBottom: "40px !important",
  minHeight: 40,
});

const SERVICE_ID = "consultoriofisioespaldae";
const TEMPLATE_ID = "template_19y23if";
const USER_ID = "VmcppMtM2cEROPpB3";

export const ContactForm = () => {
  const [sendingForm, setSendingForm] = useState(false);
  const [formFeedback, setFormFeedback] = useState({
    type: "",
    text: "",
  });
  const [showFormFeedback, setShowFormFeedback] = useState(false);

  const toggleFormFeedback = (type, text) => {
    setSendingForm(false);
    setShowFormFeedback(true);

    setFormFeedback({
      type,
      text,
    });
  };

  const handleOnSubmit = (e) => {
    console.log("evento", e);
    e.preventDefault();
    setSendingForm(true);

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID).then(
      (result) => {
        console.log(result.text);
        toggleFormFeedback("success", "Mensaje enviado con éxito");
      },
      (error) => {
        toggleFormFeedback("error", "Inténtelo de nuevo");
        console.log(error);
      }
    );
    e.target.reset();
  };

  return (
    <ContactFormContainer id="contact-form-section">
      <ContactFormTitle variant="h4">Coordina tu consulta</ContactFormTitle>
      <form onSubmit={handleOnSubmit}>
        <InputField
          id="form-input-control-email"
          type="email"
          label="Tu e-mail"
          name="reply_to"
          required
        />
        <InputField
          id="form-input-control-last-name"
          label="Tu nombre"
          name="from_name"
          required
        />
        <InputField
          id="form-input-control-last-name"
          type="tel"
          label="Tu teléfono"
          name="from_phone"
          required
        />
        {!sendingForm ? (
          <ButtonLight type="submit">Enviar</ButtonLight>
        ) : (
          <Loading />
        )}
        {showFormFeedback ? (
          <Notification type={formFeedback.type}>
            {formFeedback.text}
          </Notification>
        ) : null}
      </form>
    </ContactFormContainer>
  );
};
