import React from "react";
import styled from "styled-components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const BoxWrapper = styled(Box)({
  background: "rgb(107,180,46)",
  background:
    "linear-gradient(0deg, rgba(7,102,57,1) 8%, rgba(107,180,46,1) 100%)",
  minHeight: 96,
  padding: "16px 21px",
  color: "white",
  marginLeft: -16,
  marginRight: -16,
  "& h3": {
    fontSize: 18,
  },
  "& h2": {
    fontSize: 30,
    marginTop: 0,
    color: "#E1E289",
  },
});

export const BoxTitle = ({ title, subtitle, ...props }) => {
  return (
    <BoxWrapper {...props}>
      {subtitle && <Typography variant="h3">{subtitle}</Typography>}
      <Typography variant="h2">{title}</Typography>
    </BoxWrapper>
  );
};
